<template>
  <div>
    <button
      class="btn1 px-10 py-2"
      @click="onTopupOpen"
      :disabled="isProcessing"
    >
      {{ topupBtnText }}
    </button>
  </div>
</template>

<script>
  import { ROUTE_NAME } from '@/constants';

  export default {
    name: 'StripePayment',
    components: {},
    computed: {
      topupBtnText() {
        return this.isProcessing ? 'Processing...' : this.$t('label.topup');
      }
    },
    data: () => ({
      isProcessing: false,
      showPayment: false,

      formData: {
        fees: 0
      }
    }),
    methods: {
      onTopupOpen() {
        this.$router.push({
          name: ROUTE_NAME.PAYMENT_GATEWAY,
          query: {
            type: 'token',
            success_url: '/payment'
          }
        });
      }
    }
  };
</script>

<style scoped></style>
