<template>
  <div class="container wrapper wrapper_space-top">
    <div>
      <div class="d-flex justify-space-between">
        <h1 class="pageHeader">
          {{ $t('label.subscriptions') }}
        </h1>
      </div>
      <!-- Payment Content Start: -->
      <div class="mt-15">
        <v-row>
          <v-col md="6" class="">
            <div class="listBg pa-6" v-if="me.role == 'talent'">
              <div no-gutters class="mb-2">
                {{ mySubscriptionsObj.packageName }}
              </div>
              <v-row no-gutters class="">
                <v-col sm="6" class="pr-3 rightBorder">
                  <div class="d-flex">
                    <div>Available Casting Credit</div>
                    <div class="mx-2">
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                          <v-icon v-on="on" dark size="20" class="mb-1">
                            mdi-information-outline
                          </v-icon>
                        </template>
                        <span
                          >Available Casting Credit refers to the number of
                          videos a user can upload/submit for a casting.
                          Available Casting Credit will also be deducted for any
                          re-uploading of video for the same casting.</span
                        >
                      </v-tooltip>
                    </div>
                    <div class="ml-auto">
                      {{ mySubscriptionsObj.uploadRemaining || 0 }}
                    </div>
                  </div>
                </v-col>
                <v-col sm="6" class="pl-3">
                  <div class="d-flex">
                    <div>Expire Soon</div>
                    <div class="ml-auto">
                      {{ mySubscriptionsObj.creditRemaining || 0 }}
                    </div>
                  </div>
                </v-col>
              </v-row>
            </div>
            <div class="listBg pa-6" v-else>
              <v-row no-gutters class="mb-2">{{
                mySubscriptionsObj.packageName
              }}</v-row>
              <v-row no-gutters>
                <v-col sm="6" class="pr-3 rightBorder">
                  <div class="d-flex">
                    <div>Start Date</div>
                    <div class="ml-auto">
                      {{ mySubscriptionsObj.startFrom }}
                    </div>
                  </div>
                </v-col>
                <v-col sm="6" class="pl-3">
                  <div class="d-flex">
                    <div>Expired Date</div>
                    <div class="ml-auto">
                      {{ mySubscriptionsObj.expiredDate }}
                    </div>
                  </div>
                </v-col>
              </v-row>
            </div>
          </v-col>

          <v-col md="6" class="d-flex" style="align-items: center">
            <StripePayment />
          </v-col>
        </v-row>
        <template v-if="adminList">
          <div
            class="col-md-6 listBg pa-6 mt-5"
            v-for="admin in adminList"
            :key="admin.id"
          >
            <v-row no-gutters class="mb-2">
              <v-col sm="6">
                {{ admin.fname }}
              </v-col>
              <v-col sm="6" class="text-right">
                <a
                  class="underlineBtn white--text"
                  @click="openSubscription(admin.id)"
                >
                  {{ showRenewButton(admin.expired_date) }}
                </a>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col sm="6" class="pr-3 rightBorder">
                <div class="d-flex">
                  <div>Start Date</div>
                  <div class="ml-auto">
                    {{ admin.start_date }}
                  </div>
                </div>
              </v-col>
              <v-col sm="6" class="pl-3">
                <div class="d-flex">
                  <div>Expired Date</div>
                  <div class="ml-auto">
                    {{ admin.expired_date }}
                  </div>
                </div>
              </v-col>
            </v-row>
          </div>
        </template>
        <div class="" v-for="(nav, index) in PaymentNav" :key="index">
          <v-row
            @click="nav.action"
            no-gutters
            class="py-7 justify-space-between cursor-pointer"
          >
            <v-col cols="11">{{ $t(`label.${nav.name}`) }}</v-col>
            <v-col class="text-center" cols="1"></v-col>
          </v-row>
          <hr class="divider" />
        </div>
        <!-- Payment Content End: -->
      </div>
    </div>
    <v-dialog v-model="openSubscriptionModal" width="auto">
      <div
        class="d-flex subscription-modal"
        :class="$isMobile() ? '' : 'justify-space-around'"
      >
        <template v-for="(item, index) in paymentSubscriptionsObj">
          <div
            v-if="item.level == 1"
            :key="index"
            class="d-flex flex-column listBg py-5 subscription-panel"
          >
            <div class="text-uppercase">
              <div class="px-12 py-2 font-weight-bold">
                {{ $t('label.subscriptions') }}
              </div>
              <div class="px-12 py-2 btn3 font-weight-bold">
                {{ item.name }}
              </div>
              <div
                class="px-12 py-2 font-weight-bold pink2--text"
                v-if="role == 'talent'"
              >
                <div>
                  {{ item.addonVideoCount }}
                  {{ $t('label.videoCasting') }}
                </div>
                <div>submission per month</div>
              </div>
              <div
                class="px-12 py-2 font-weight-bold pink2--text"
                v-else-if="
                  ['agency', 'production_house', 'freelancer'].includes(role)
                "
              >
                <div>Free subscription for first year</div>
              </div>
              <!-- <div class="px-12 text-none sm-font" v-if="role == 'talent'">
                SGD {{ item.addonVideoPrice }} for additional
                {{ item.addonVideoCount }} video casting submission
              </div> -->
            </div>
            <div class="featureList mx-2 px-10">
              <template v-if="role !== 'freelancer'">
                <h3 class="text-decoration-underline">Features</h3>
                <ul>
                  <li v-for="(feature, f) in getFeatures(index)" :key="f">
                    {{ feature }}
                  </li>
                </ul>
              </template>
            </div>
            <div class="px-12 pb-2">
              <span class="font-weight-bold">{{
                $t('label.subscriptions')
              }}</span>
              <div v-for="(packItem, n) in item.packageDetails" :key="n">
                <v-checkbox
                  v-model="selectedPackage"
                  :disabled="
                    packItem.id == mySubscriptionsObj.packageId || !canPayment
                  "
                  :class="
                    selectedPackage == mySubscriptionsObj.packageId &&
                    packItem.id == mySubscriptionsObj.packageId
                      ? 'disabled-checked'
                      : ''
                  "
                  dark
                  color="primary"
                  on-icon="mdi-check-circle-outline"
                  off-icon="mdi-checkbox-blank-circle-outline"
                  :value="packItem.id"
                  hide-details
                  dense
                >
                  <template v-slot:label v-if="role == 'talent'">
                    <div class="sm-font white--text pl-1">
                      <div>
                        SGD {{ packItem.packPrice.toFixed(2) }}
                        {{
                          packItem.packageDuration == 1
                            ? ' per month'
                            : ` for ${packItem.packageDuration} months`
                        }}
                      </div>
                      <div
                        v-if="packItem.packageDiscount"
                        class="font-weight-light"
                      >
                        ({{ packItem.packageDiscount }}% Discount)
                      </div>
                    </div>
                  </template>
                  <template
                    v-slot:label
                    v-else-if="
                      ['agency', 'production_house', 'freelancer'].includes(
                        role
                      )
                    "
                  >
                    <div class="sm-font white--text pl-3">
                      <div>
                        SGD {{ packItem.pricePerAnnum.toFixed(2) }} per Annum
                      </div>
                      <div class="font-weight-light">
                        (Up to {{ packItem.noOfUsers }} users)
                      </div>
                    </div>
                  </template>
                </v-checkbox>
              </div>
              <div>
                <!-- <v-checkbox
                  v-if="
                    role == 'talent' &&
                    item.packageDetailIds.includes(mySubscriptionsObj.packageId)
                  "
                  v-model="selectedPackage"
                  on-icon="mdi-check-circle-outline"
                  off-icon="mdi-checkbox-blank-circle-outline"
                  dark
                  hide-details
                  color="primary"
                  :value="'talent'"
                  dense
                >
                  <template v-slot:label>
                    <div class="sm-font white--text pl-1">
                      <div>
                        Futher add on SGD{{
                          item.addonVideoPrice.toFixed(2)
                        }}
                        (for {{ item.addonVideoCount }} additional video)
                      </div>
                    </div>
                  </template>
                </v-checkbox> -->
                <!-- <v-checkbox
                  v-if="
                    ['agency', 'production_house'].includes(role) &&
                    item.packageDetailIds.includes(
                      mySubscriptionsObj.packageId
                    ) &&
                    mySubscriptionsObj.buyAdmin
                  "
                  v-model="selectedPackage"
                  on-icon="mdi-check-circle-outline"
                  off-icon="mdi-checkbox-blank-circle-outline"
                  dark
                  hide-details
                  color="primary"
                  :value="'production'"
                >
                  <template v-slot:label>
                    <div class="sm-font white--text pl-3">
                      <div>SGD{{ getPricePerAdmin(item) }}</div>
                      <div class="font-weight-light">
                        (For additional Administrator Account)
                      </div>
                    </div>
                  </template>
                </v-checkbox> -->
              </div>
            </div>
            <div class="px-12 mt-auto">
              <hr class="divider" />
              <div class="text-center sm-font pt-3">
                By clicking subscribe you are agree to the
                <router-link
                  :to="{
                    name: routeName.TERMS_OF_USE
                  }"
                  target="_blank"
                  >{{ $t('label.termsOfUse') }}</router-link
                >
              </div>
              <div class="text-center">
                <v-btn
                  :disabled="
                    checkSelectedDisabled(selectedPackage, item.packageDetails)
                  "
                  @click="paySubscribe(item.id, selectedPackage)"
                  class="btn1 md mt-3"
                  >{{ $t('label.subscribe') }}</v-btn
                >
              </div>
            </div>
          </div>
        </template>
      </div>
    </v-dialog>
  </div>
</template>

<script>
  import { ROUTE_NAME } from '@/constants';
  import {
    PAYMENT_GET_PAYMENT_SUBSCRIPTIONS,
    PAYMENT_GET_MY_SUBSCRIPTIONS,
    PAYMENT_SET_PAY_SUBSCRIPTION,
    PAYMENT_SET_PAY_UPLOAD_CREDIT,
    PAYMENT_INITIAL_GET_PAYMENT_SUBSCRIPTIONS_STATE,
    PAYMENT_INITIAL_GET_MY_SUBSCRIPTIONS_STATE,
    PAYMENT_INITIAL_SET_PAY_SUBSCRIPTION_STATE,
    PAYMENT_INITIAL_SET_PAY_UPLOAD_CREDIT_STATE,
    PAYMENT_PURCHASE_ADMIN_ACCOUNT,
    PAYMENT_INITIAL_PURCHASE_ADMIN_ACCOUNT_STATE,
    PAYMENT_CHECK_PAYMENT_SUBSCRIPTION,
    PAYMENT_INITIAL_CHECK_PAYMENT_SUBSCRIPTION_STATE
  } from '@/store/payment.module';
  import {
    PROFILE_GET_ADMIN_LIST,
    PROFILE_INITIAL_GET_ADMIN_LIST_STATE
  } from '@/store/profile.module';
  import StripePayment from '@/components/payment/StripePayment.vue';

  export default {
    name: 'Payment',
    data: () => ({
      paymentSubscriptionsObj: [],
      mySubscriptionsObj: {},
      openSubscriptionModal: false,
      selectedPackage: null,
      routeName: ROUTE_NAME,
      adminList: null,
      renewId: null,
      canPayment: true,
      tempData: [
        {
          idx: 0,
          content: [
            'Notification when there is a suitable casting',
            'Reminder alert to submit casting',
            'Upload up to 3 profile photos',
            '1 video show reel',
            'SGD3 for additional casting video submission'
          ]
        },
        {
          idx: 1,
          content: [
            'Notification when there is a suitable casting',
            'Reminder alert to submit casting',
            'Upload up to 3 profile photos',
            '2 video show reels',
            'Up to 5 casting video submissions',
            'SGD8 for additional 3 casting video submissions'
          ]
        },
        {
          idx: 2,
          content: [
            'Notification when there is a suitable casting',
            'Reminder alert to submit casting',
            'Upload up to 5 profile photos',
            '3 video show reels',
            'Up to 10 casting video submissions',
            'SGD6 for additional 3 casting video submissions',
            'Messages to production houses are on priority and are pushed to the top',
            'Messages that are not replied, AI will send a reminder to Production House / Producer / Talent / Talent Agency to reply',
            'Priority listing on casting matches',
            'Being featured on IAmCasting social'
          ]
        }
      ]
    }),
    components: {
      StripePayment
    },
    async mounted() {
      await this.initialGetAuth();
      if (this.$route.params.purchaseId) {
        setTimeout(() => {
          this.selectedPackage = this.$route.params.purchaseId;
          this.openSubscriptionModal = true;
        }, 800);
      }
    },
    computed: {
      me() {
        return this.$store.state.auth.me.data;
      },
      PaymentNav() {
        return [
          // {
          //   name: 'membersInPlan',
          //   action: () => this.$router.push({ name: 'payment-member-plan' })
          // },
          {
            name: 'membershipSubscriptions',
            action: () => {
              this.openSubscription();
            }
          },
          {
            name: 'paymentHistory',
            action: () => this.$router.push({ name: 'payment-history' })
          }
        ];
      },
      mySubscriptionsComplated() {
        return this.$store.state.payment.mySubscriptions.complete;
      },
      paymentSubscriptionsComplated() {
        return this.$store.state.payment.paymentSubscriptions.complete;
      },
      paySubscriptionsComplated() {
        return this.$store.state.payment.paySubscriptions.complete;
      },
      payUploadCreditComplated() {
        return this.$store.state.payment.payUploadCredit.complete;
      },
      getAdminListCompleted() {
        return this.$store.state.profile.adminList.complete;
      },
      purchaseAdminAccountCompleted() {
        return this.$store.state.payment.purchaseAdminAccount.complete;
      },
      checkPaymentSubscriptionCompleted() {
        return this.$store.state.payment.checkPaymentSubscription.complete;
      },
      role() {
        return this.$store.state.auth.me.data.role;
      }
    },
    watch: {
      mySubscriptionsComplated() {
        let response = this.$store.state.payment.mySubscriptions;
        if (response.complete) {
          this.importMySubscriptsObj(response);
        }
      },
      paymentSubscriptionsComplated() {
        let response = this.$store.state.payment.paymentSubscriptions;
        if (response.complete) {
          this.importPaymentSubscriptsObj(response);
        }
      },
      paySubscriptionsComplated() {
        let response = this.$store.state.payment.paySubscriptions;
        if (response.complete) {
          this.importPaySubscriptsObj(response);
        }
      },
      payUploadCreditComplated() {
        let response = this.$store.state.payment.payUploadCredit;
        if (response.complete) {
          this.importPayUploadCreditObj(response);
        }
      },
      getAdminListCompleted() {
        let response = this.$store.state.profile.adminList;
        if (response.complete) {
          this.importAdminList(response);
        }
      },
      purchaseAdminAccountCompleted() {
        let response = this.$store.state.payment.purchaseAdminAccount;
        if (response.complete) {
          this.purchaseAdminAccountCompleteAction(response);
        }
      },
      checkPaymentSubscriptionCompleted() {
        let response = this.$store.state.payment.checkPaymentSubscription;
        if (response.complete) {
          this.checkPaymentSubscriptionCompleteAction(response);
        }
      },
      openSubscriptionModal(val) {
        if (!val) {
          this.renewId = null;
          this.selectedPackage = this.mySubscriptionsObj.packageId;
        }
      }
    },
    methods: {
      initialGetAuth() {
        this.initialGetPaymentSubscripts();
        this.initialGetMySubscripts();
        this.initialCheckPaymentSubscription();
        if (['agency', 'production_house'].includes(this.role)) {
          this.getAdminList();
        }
      },
      importPaymentSubscriptsObj(response) {
        this.paymentSubscriptionsObj = response.data;
        if (this.paymentSubscriptionsObj) {
          this.paymentSubscriptionsCompleteAction();
        }
      },
      importMySubscriptsObj(response) {
        this.mySubscriptionsObj = response.data;
        this.selectedPackage = this.selectedPackage || response.data.packageId;
        if (this.mySubscriptionsObj) {
          this.mySubscriptionsCompleteAction();
        }
      },
      importPaySubscriptsObj(response) {
        let url = response.data;
        if (url) {
          window.open(url);
          this.PaySubscriptionsCompleteAction();
          // this.$router.push({ name: ROUTE_NAME.HOME });
        }
      },
      importPayUploadCreditObj(response) {
        let url = response.data;
        if (url) {
          window.open(url);
          this.PaySubscriptionsCompleteAction();
          // this.$router.push({ name: ROUTE_NAME.HOME });
        }
      },
      mySubscriptionsCompleteAction() {
        this.initialGetMySubscriptsState();
      },
      paymentSubscriptionsCompleteAction() {
        this.initialGetPaymentSubscriptsState();
      },
      PaySubscriptionsCompleteAction() {
        this.initialSetPaySubscriptsState();
      },
      purchaseAdminAccountCompleteAction(response) {
        let url = response.data;
        if (url) {
          window.open(url);
          this.$store.dispatch(PAYMENT_INITIAL_PURCHASE_ADMIN_ACCOUNT_STATE);
          // this.$router.push({ name: ROUTE_NAME.HOME });
        }
        if (response.code != 200) {
          this.openSubscriptionModal = false;
          this.$openModal('messageModal', response.message);
        }
      },
      initialGetMySubscripts() {
        this.$store.dispatch(PAYMENT_GET_MY_SUBSCRIPTIONS, { role: this.role });
      },
      initialGetPaymentSubscripts() {
        let data = {
          role: ['talent'].includes(this.role) ? 'talent' : 'production'
        };
        this.$store.dispatch(PAYMENT_GET_PAYMENT_SUBSCRIPTIONS, {
          data,
          userRole: this.role
        });
      },
      initialCheckPaymentSubscription() {
        this.$store.dispatch(PAYMENT_CHECK_PAYMENT_SUBSCRIPTION);
      },
      checkPaymentSubscriptionCompleteAction(response) {
        if (response.code == 200) {
          this.canPayment = response.data.can_payment;
        }
        this.$store.dispatch(PAYMENT_INITIAL_CHECK_PAYMENT_SUBSCRIPTION_STATE);
      },
      initialGetMySubscriptsState() {
        this.$store.dispatch(PAYMENT_INITIAL_GET_MY_SUBSCRIPTIONS_STATE);
      },
      initialGetPaymentSubscriptsState() {
        this.$store.dispatch(PAYMENT_INITIAL_GET_PAYMENT_SUBSCRIPTIONS_STATE);
      },
      initialSetPaySubscriptsState() {
        this.$store.dispatch(PAYMENT_INITIAL_SET_PAY_SUBSCRIPTION_STATE);
      },
      initialGetPayUploadCreditState() {
        this.$store.dispatch(PAYMENT_INITIAL_SET_PAY_UPLOAD_CREDIT_STATE);
      },
      importAdminList(response) {
        if (response.code == 200) {
          this.adminList = response.data?.activated;
        }
        this.$store.dispatch(PROFILE_INITIAL_GET_ADMIN_LIST_STATE);
      },
      getAdminList() {
        this.$store.dispatch(PROFILE_GET_ADMIN_LIST);
      },
      openSubscription(id = null) {
        this.renewId = id;

        if (id) {
          this.selectedPackage = 'production';
          this.paySubscribe(id, this.selectedPackage);
        } else {
          this.openSubscriptionModal = !this.openSubscriptionModal;
        }
      },
      checkSelectedDisabled(value, packages) {
        let packagesId = [];

        if (!packagesId.length) {
          packages.forEach((x) => {
            if (x.packPrice != 0 && x.id != this.mySubscriptionsObj.packageId) {
              // to exclude the free option and user subscribed package id
              packagesId.push(x.id);
            }
            if (x.id == this.mySubscriptionsObj.packageId) {
              packagesId.push(this.role == 'talent' ? 'talent' : 'production');
            }
          });
        }
        if (packagesId.includes(value)) {
          return false;
        } else {
          return true;
        }
      },
      paySubscribe(packageId, packageDetailId) {
        let data = {
          subscriptionType: ['talent'].includes(this.role)
            ? 'talent'
            : 'production',
          packageId: packageId,
          packageDetailId: packageDetailId
        };
        if (packageDetailId == 'talent') {
          this.$store.dispatch(PAYMENT_SET_PAY_UPLOAD_CREDIT);
        } else if (packageDetailId == 'production') {
          this.$store.dispatch(PAYMENT_PURCHASE_ADMIN_ACCOUNT, {
            data: {
              type: this.renewId ? 'renew' : 'new',
              userId: this.renewId
            }
          });
        } else {
          this.$store.dispatch(PAYMENT_SET_PAY_SUBSCRIPTION, { data });
        }
      },
      showRenewButton(date) {
        const today = new Date();
        const theDate = new Date(date + ' 0:00:00');

        return today > theDate ? 'Renew' : '';
        // return true
      },
      getPricePerAdmin(item) {
        const p = item.packageDetails.find(
          (x) => x.id == this.mySubscriptionsObj.packageId
        );
        return p.pricePerAdmin.toFixed(2);
      },
      getFeatures(index) {
        return this.tempData.find((x) => x.idx == index).content;
      },
      onTopUpBtnClick() {}
    }
  };
</script>

<style scoped lang="scss">
  .rightBorder {
    border-right: 1px solid #fff;
  }
  .v-icon.v-icon {
    color: #fff;
  }
  .disabled-checked {
    color: #ff2ff2;
  }
  .featureList {
    height: 250px;
    overflow-y: auto;
    scrollbar-width: none;
    scrollbar-color: #ffffff #7d7d7d;

    /* Chrome, Edge, and Safari */
    &::-webkit-scrollbar {
      width: 6px;
    }

    &::-webkit-scrollbar-track {
      background: transparent;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #ffffff;
      border-radius: 10px;
      border: 5px none #ffffff;
    }
  }

  .subscription-modal {
    gap: 28px;

    .subscription-panel {
      --w-size: 90vw;
      max-height: 600px;
      min-width: var(--w-size);
      width: var(--w-size);
      max-width: var(--w-size);
    }
  }

  @media only screen and (min-width: 426px) {
    .subscription-modal {
      .subscription-panel {
        --w-size: 350px;
        max-height: 600px;
      }
    }
  }
</style>
